.expertiesGroup {
  margin-top: 3rem;
}

.expertiesItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expertiesItem__logoShape {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  padding: 14px;
  background-color: var(--background-light);
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
  border: 2px solid #f06195bd;
  transform: translateY(7.5px);
}

.expertiesItem__logoShape:hover {
  transform: translateY(0px);
  transition: transform 0.5s;
}

.expertiesItem__logoShape > img {
  width: 75px;
}

.MuiGrid-spacing-xs-2 {
  width: 100% !important;
}
