.headerAppBar.shadowed {
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.3);
}

.headerLogo {
  width: 90px;
  cursor: pointer;
}

.navItem {
  font-family: var(--codefont);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: var(--secondary-light);
  padding: 0 10px;
  cursor: pointer;
}

.navItem > span {
  font-weight: 400;
  font-size: 14px;
}

.mobileNav__header {
  padding: 0.25rem 1.5rem 0.25rem 0.2rem;
}
